<template>
    <div class="content">
        <div class="accountCon">

            <!--列表-->
            <el-row>
                <el-col :span="24">
                    <el-table
                        :data="list"
                        size="small"
                        border
                        :cell-style="$style.cellStyle"
                        :header-cell-style="$style.rowClass"
                    >
                        <ElTableColumn label="成交日期" prop="deal_time" width="90" />
                        <ElTableColumn label="客户名称" prop="consignee" />
                        <ElTableColumn label="客户K3代码" prop="king_no" width="100"/>
                        <ElTableColumn label="门店" prop="shop_name" />
                        <ElTableColumn label="护理师" prop="sale_name" />
                        <ElTableColumn label="收款方式" prop="refund_mode_name" width="130"/>
                        <ElTableColumn label="本金" prop="pay_price" />
                        <ElTableColumn label="赠金" prop="gift_price" />
                        <ElTableColumn label="收款金额" prop="pay_price" />
                        <ElTableColumn label="业务类型" prop="business_type" />
                        <ElTableColumn label="订单类型" prop="order_type" width="70"/>
                        <ElTableColumn label="附加类型" prop="extra_type" width="70"/>
                        <ElTableColumn label="订单状态" prop="status_msg" />
                        <ElTableColumn label="收款类型" prop="refund_type" width="70"/>

                        <ElTableColumn label="操作" fixed="right" width="160">
                            <template slot-scope="{ row }">
                                <span class="theme" v-if="clienId"  @click="tapBtn('客户收款单详情',row)">查 看</span>
                                <span class="theme" v-else @click="tapBtn('收款单详情',row)">查 看</span>
                            </template>
                        </ElTableColumn>
                    </el-table>

                    <pagination
                        class="page tc mt10"
                        :total="page.total"
                        :page.sync="page.page"
                        :limit.sync="page.limit"
                        @pagination="getList"
                    />
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { turnPerformanceList } from "@/api/order";

class Page {
  total = 0;
  page = 1;
  limit = 10;
}

export default {
    name: "OrderList",
    components: {
        pagination
    },
    props: {
        clienId: {
        type: String,
        default: ""
        },
        detail: {
        type: Object,
        default: () => {
            return {};
        }
        }
    },
    data() {
        return {
            page: new Page(), // 分页
            list: [], // 数据展示
        };
    },
    computed: {
        ...mapGetters(["userInfo"])
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList(){
            turnPerformanceList({
                id:1111,
            }).then(res => {
                console.log(res);   
                this.list = res.list;
                this.page.total = res.dataCount; 
            });
        },

        // 点击详情
        tapBtn(type, row) {
            switch (type) {
                case "收款单详情":
                this.$router.push(
                    "./refundDetail?id=" +
                    row.id +
                    "&type=" +
                    row.type +
                    "&gathering=true&noKing=1"
                );
                break;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.reveal {
  text-align: right;
  padding: 20px 0;
  .peril {
    font-weight: bold;
  }
}
</style>